import React from 'react';
import Layout from '../components/indexLayout';
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const NotFoundPage = props => (
  <Layout>
    <div className="page">
    <div className="container">
      <h1>Seite nicht gefunden! :-(</h1>
        <Img
          fluid={props.data.imageFirst.childImageSharp.fluid}
          className="hero-image"
          alt="Seite nicht gefunden!"
        />
      <p>
        Sie haben eine Seite aufgerufen die nicht existiert. 
        Bitte rufen Sie die <Link style={{"color": "#373738"}} to="/">Startseite</Link> auf oder 
        betätigen "Eine Seite zurück"/"Zurück" Ihres Webbrowsers. 
      </p>
      </div>
    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageFirst: file(relativePath: { eq: "images/undraw_not_found_60pq.png" }) {
      ...firstSiteImages
    }
  }
`

export default NotFoundPage
